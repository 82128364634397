.mdp-animate-next {
  opacity: 0;
  -webkit-transform: translate3d(50%, 0, 1px);
          transform: translate3d(50%, 0, 1px);
}
.mdp-animate-next-remove {
  -webkit-transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  opacity: 0;
  -webkit-transform: translate3d(50%, 0, 1px);
          transform: translate3d(50%, 0, 1px);
}
.mdp-animate-next-remove-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 1px);
          transform: translate3d(0, 0, 1px);
}
.mdp-animate-prev {
  opacity: 0;
  -webkit-transform: translate3d(-50%, 0, 1px);
          transform: translate3d(-50%, 0, 1px);
}
.mdp-animate-prev-remove {
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  opacity: 0;
  -webkit-transform: translate3d(-50%, 0, 1px);
          transform: translate3d(-50%, 0, 1px);
}
.mdp-animate-prev-remove-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 1px);
          transform: translate3d(0, 0, 1px);
}
@-webkit-keyframes mdp-animation-bounce {
  from {
    opacity: 0;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes mdp-animation-bounce {
  from {
    opacity: 0;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.mdp-animation-zoom.ng-enter {
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: mdp-animation-bounce;
          animation-name: mdp-animation-bounce;
}
.mdp-datepicker {
  max-height: initial;
  min-width: 234px;
}
.mdp-datepicker * {
  outline: 0;
}
.mdp-datepicker .md-actions {
  width: 100%;
  padding: 0px 5px;
}
.mdp-calendar-week-days {
  font-size: 0.75rem;
  opacity: 0.6;
}
.mdp-calendar-week-days > * {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 0 5px;
  padding: 0;
  min-width: 0px;
  min-height: 0px;
  box-shadow: none !important;
  background-color: transparent;
}
.mdp-calendar-days {
  font-size: 1rem;
  max-width: 100%;
}
.mdp-calendar-days .md-button,
.mdp-calendar-days .mdp-day-placeholder {
  width: 32px;
  height: 32px;
  margin: 0 5px;
}
.mdp-calendar-days .md-button {
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  min-width: 0px;
  min-height: 0px;
  box-shadow: none !important;
  background-color: transparent;
}
.mdp-calendar-days .md-button[disabled]:not(.md-accent) {
  background-color: transparent !important;
}
.mdp-calendar-monthyear {
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 32px;
  min-height: 32px;
}
.mdp-datepicker-date,
.mdp-datepicker-day,
.mdp-datepicker-dow,
.mdp-datepicker-month {
  font-size: 1.8rem;
  opacity: 0.6;
}
.mdp-datepicker-date:not(.active),
.mdp-datepicker-day:not(.active),
.mdp-datepicker-dow:not(.active),
.mdp-datepicker-month:not(.active) {
  cursor: pointer;
}
.mdp-datepicker-date.active,
.mdp-datepicker-day.active,
.mdp-datepicker-dow.active,
.mdp-datepicker-month.active,
.mdp-datepicker-date:hover,
.mdp-datepicker-day:hover,
.mdp-datepicker-dow:hover,
.mdp-datepicker-month:hover {
  opacity: 1;
}
.mdp-datepicker-year {
  font-size: 0.9rem;
  opacity: 0.6;
  padding: 0;
  margin: 0;
}
.mdp-datepicker-year:not(.active) {
  cursor: pointer;
}
.mdp-datepicker-year.active,
.mdp-datepicker-year:hover {
  opacity: 1;
}
.mdp-datepicker-select-year {
  height: 232px;
}
.mdp-datepicker-select-year .repeated-year {
  text-align: center;
}
.mdp-datepicker-select-year .repeated-year .md-button {
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 0;
  font-size: 1.0rem;
  line-height: 42px;
}
.mdp-datepicker-select-year .repeated-year .md-button.current {
  font-size: 1.8rem;
  line-height: 42px;
}
.mdp-datepicker-select-year .md-virtual-repeat-container,
.mdp-datepicker-select-year .md-virtual-repeat-offsetter,
.mdp-datepicker-select-year .md-virtual-repeat-scroller {
  height: 100%;
  width: 100%;
}
mdp-date-picker > md-input-container.md-has-icon {
  padding-left: 40px;
}
mdp-date-picker .md-button.md-icon-button {
  margin: 18px 0 0 0;
}
.mdp-datepicker:not(.portrait) .mdp-datepicker-select-year {
  width: 309px;
}
.mdp-datepicker:not(.portrait) .mdp-calendar {
  margin-right: 5px;
  width: 294px;
  margin-left: 10px;
}
.mdp-datepicker:not(.portrait) .mdp-datepicker-date-wrapper {
  width: 150px;
}
.mdp-datepicker:not(.portrait) .mdp-datepicker-dow {
  width: 100%;
  display: block;
}
.mdp-datepicker:not(.portrait) .mdp-calendar-week-days > *,
.mdp-datepicker:not(.portrait) .mdp-calendar-days > * {
  width: 42px;
}
.mdp-datepicker .mdp-datepicker-date-wrapper {
  padding: 16px 35px 16px 16px;
}
.mdp-datepicker md-dialog-content {
  overflow: hidden;
  padding: 0px;
}
.mdp-datepicker md-dialog-content .mdp-calendar {
  width: 294px;
  overflow-x: hidden;
}
.mdp-datepicker.portrait {
  max-width: 234px;
}
.mdp-datepicker.portrait .mdp-calendar {
  text-align: center;
  width: 100%;
}
.mdp-datepicker.portrait .mdp-datepicker-select-year {
  height: 252px;
}
.mdp-datepicker.portrait md-dialog-content > * {
  width: 100%;
}
.mdp-datepicker.portrait .mdp-calendar-week-days,
.mdp-datepicker.portrait .mdp-calendar-days,
.mdp-datepicker.portrait .md-actions {
  padding: 0 5px;
}
.mdp-datepicker.portrait .md-actions {
  margin-top: 20px;
}
.mdp-datepicker.portrait .mdp-calendar-week-days > *,
.mdp-datepicker.portrait .mdp-calendar-days > * {
  width: 32px;
}
mdp-calendar {
  display: block;
}
.mdp-calendar-week-days {
  width: 100%;
}
.mdp-calendar-week-days > * {
  margin: 0;
}
.mdp-calendar-days .md-button,
.mdp-calendar-days .mdp-day-placeholder {
  margin: 0;
}
.mdp-clock {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  cursor: pointer;
  padding: 24px;
  background: #ededed;
}
.mdp-clock .md-button {
  box-shadow: none !important;
  background-color: transparent;
  display: block;
  position: absolute;
  min-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.mdp-clock .mdp-clock-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}
.mdp-clock .mdp-pointer {
  min-height: 0px;
  width: 1px;
  height: 50%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  z-index: 0;
  pointer-events: none;
}
.mdp-clock .mdp-clock-center {
  min-height: 0px;
  height: 6px;
  width: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}
.mdp-clock .md-button.mdp-clock-selected {
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  bottom: -8px;
  left: 0px;
  min-width: 0;
  min-height: 0;
  pointer-events: none;
}
.mdp-timepicker .mdp-clock-switch-container {
  padding: 20px;
  width: 309px;
}
.mdp-timepicker .mdp-timepicker-time {
  padding: 15px;
}
.mdp-timepicker .mdp-timepicker-selected-time {
  font-size: 3rem;
}
.mdp-timepicker:not(.portrait) .mdp-timepicker-time {
  width: 138px;
}
.mdp-timepicker.portrait .mdp-timepicker-selected-time {
  font-size: 4rem;
  margin-right: 1.5rem;
}
mdp-time-picker md-input-container.md-has-icon {
  padding-left: 40px;
}
mdp-time-picker .md-button.md-icon-button {
  margin: 18px 0 0 0;
}
.mdp-timepicker-selected-time > span,
.mdp-timepicker-selected-ampm > span {
  outline: 0;
  opacity: 0.6;
}
.mdp-timepicker-selected-time > span:not(.active),
.mdp-timepicker-selected-ampm > span:not(.active) {
  cursor: pointer;
}
.mdp-timepicker-selected-time > span.active,
.mdp-timepicker-selected-ampm > span.active {
  opacity: 1;
}
.mdp-clock-deg0 {
  top: 0%;
  left: 50%;
}
.mdp-clock-deg30 {
  top: 6.69872981%;
  left: 75%;
}
.mdp-clock-deg60 {
  top: 25%;
  left: 93.30127019%;
}
.mdp-clock-deg90 {
  top: 50%;
  left: 100%;
}
.mdp-clock-deg120 {
  top: 75%;
  left: 93.30127019%;
}
.mdp-clock-deg150 {
  top: 93.30127019%;
  left: 75%;
}
.mdp-clock-deg180 {
  top: 100%;
  left: 50%;
}
.mdp-clock-deg210 {
  top: 93.30127019%;
  left: 25%;
}
.mdp-clock-deg240 {
  top: 75%;
  left: 6.69872981%;
}
.mdp-clock-deg270 {
  top: 50%;
  left: 0%;
}
.mdp-clock-deg300 {
  top: 25%;
  left: 6.69872981%;
}
.mdp-clock-deg330 {
  top: 6.69872981%;
  left: 25%;
}
.mdp-clock-deg360 {
  top: 0%;
  left: 50%;
}
